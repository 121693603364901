<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-button v-b-modal.new-modal variant="success">ثبت زیر منو</b-button>

        <b-card v-if="menus" class="mt-4" :title="' لیست زیر منو ' + menuTitle">

            <table class="table table-responsive-sm table-striped">
                <thead>
                    <tr>
                        <th scope="col"> <i class="fas fa-arrows-alt-v"></i>
                            شناسه</th>
                        <th scope="col">عنوان</th>
                        <th scope="col">زیرمنوها</th>
                        <th scope="col">وضعیت</th>
                        <th scope="col">ویرایش</th>
                        <th scope="col">حذف</th>
                    </tr>
                </thead>
                <draggable v-model="menus" tag="tbody" @change="log" v-if=" menus != '' ">
                    <tr v-for="(item, index) in menus" :key="item.id">
                        <td scope="row">{{item.id}}</td>
                        <td scope="row">
                            <a :href="'/admin/menu/'+ item.id +'/subMenu'">
                                {{ item.title }}
                            </a>
                        </td>
                        <td scope="row">
                            <router-link :to="{name:'admin.menu.subMenu', params: {id: item.id}}">
                                <b-button variant="warning">زیر منوها</b-button>
                            </router-link>
                        </td>
                        <td>
                            <div style="width: 60px;" class="mt-2">
                                <p v-if="item.status == '1'" class="py-1 px-2 bg-success text-center small rounded">فعال
                                </p>
                                <p v-else-if="item.status == '0'" class="p-1 bg-danger text-center small rounded">
                                    غیرفعال</p>
                            </div>
                        </td>
                        <td>
                            <b-button variant="primary" @click="editItem(index)"><i class="fas fa-edit"></i></b-button>
                        </td>
                        <td>
                            <b-button variant="danger" @click="deleteItem(index)"><i class="fa fa-close"></i></b-button>
                        </td>
                    </tr>
                </draggable>
            </table>
            <div class="alert alert-danger" v-if=" menus == '' ">
                <span>موردی یافت نشد ! </span>
            </div>
        </b-card>


        <b-modal id="new-modal" size="lg" title="ثبت آیتم" hide-footer>
            <form @submit.prevent="newFile" id="new-file">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group>
                            <label for="title">عنوان <i class="fas fa-asterisk text-danger"
                                    style="font-size: 0.5rem;"></i></label>
                            <b-form-input id="title" name="title" :disabled="disabled"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group>
                            <label for="type">نوع <i class="fas fa-asterisk text-danger"
                                    style="font-size: 0.5rem;"></i></label>
                            <b-form-select id="type" name="type" @change="loadLinks" :disabled="disabled">
                                <b-form-select-option v-for="(item, name ) in types" :key="item.id" :value="name">
                                    {{ item }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <b-form-group v-if="links === null" label="لینک" label-for="link">
                    <b-form-input id="link" name="link" :disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group v-else-if="links " label="لینک" label-for="link">
                    <input type="hidden" name="link" :value="link.id">
                    <multiselect open-direction="bottom" v-model="link" :options="links"
                        :label="(links[0].title) ? 'title' : 'name'" track-by="id" :searchable="true"
                        :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
                </b-form-group>
                <b-form-group label="توضیح" label-for="description">
                    <b-form-textarea id="description" name="description" :disabled="disabled"></b-form-textarea>
                </b-form-group>
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group label="target" label-for="target">
                            <b-form-select id="target" name="target" v-model="selected_target" :disabled="disabled">
                                <b-form-select-option value="_parent">_parent</b-form-select-option>
                                <b-form-select-option value="_blank">_blank</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label="تصویر" label-for="image">
                            <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                        </b-form-group>
                    </div>
                </div>
                <div class="form-group">
                    <label class="custom-switch">
                        <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                            :disabled="disabled">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">وضعیت</span>
                    </label>
                </div>
                <input type="hidden" name="order" value="1">
                <input type="hidden" name="class_css" value="">
                <input type="hidden" name="parent_id" :value="parentId">
                <input type="hidden" name="menu_id" :value="menuId">
                <button class="btn btn-success" type="submit" :disabled="disabled">ثبت</button>
            </form>
        </b-modal>
        <b-modal id="edit-modal" size="lg" title="ویرایش آیتم" hide-footer>
            <div v-if="edit.id">
                <form @submit.prevent="editFile" id="edit-file">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="title">عنوان <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-input id="title" name="title" v-model="edit.title" :disabled="disabled">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <label for="type">نوع <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-select id="type" name="type" v-model="edit.type" @change="loadLinks"
                                    data-live-search="true" :disabled="disabled">
                                    <b-form-select-option v-for="(item, name ) in types" :key="item.id" :value="name">
                                        {{ item }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div v-if="edit.type == 'before' ">

                    </div>
                    <div v-else>
                        <b-form-group v-if="links === null" label="لینک" label-for="link">
                            <b-form-input id="link" name="link" :disabled="disabled"></b-form-input>
                        </b-form-group>
                        <b-form-group v-else-if="links" label="لینک" label-for="link">
                            <input type="hidden" name="link" :value="link.id">
                            <multiselect open-direction="bottom" v-model="link" :options="links"
                                :label="(links[0].title) ? 'title' : 'name'" track-by="id" :searchable="true"
                                :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
                        </b-form-group>
                    </div>
                    <b-form-group label="توضیح" label-for="description">
                        <b-form-textarea id="description" name="description" v-model="edit.description"
                            :disabled="disabled"></b-form-textarea>
                    </b-form-group>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group label="target" label-for="target">
                                <b-form-select id="target" name="target" v-model="edit.target" :disabled="disabled">
                                    <b-form-select-option value="_parent">_parent</b-form-select-option>
                                    <b-form-select-option value="_blank">_blank</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="تصویر" label-for="image">
                                <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="custom-switch">
                            <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                                :disabled="disabled">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">وضعیت</span>
                        </label>
                    </div>

                    <input type="hidden" name="class_css" value="">
                    <input type="hidden" name="parent_id" value="edit.parent_id">
                    <input type="hidden" name="order" v-model="edit.order">
                    <input type="hidden" name="menu_id" v-model="edit.menu_id">
                    <input type="hidden" name="_method" value="PUT">
                    <button class="btn btn-primary" type="submit" :disabled="disabled">ویرایش</button>
                </form>
            </div>
        </b-modal>
    </div>

</template>
<script>
    import mixins from '../mixins/mixins'
    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/menu-items',
                menuId: '',
                selected_status: '1',
                menus: [],
                title: 'آیتم های زیر منو',
                selected_target: '_parent',
                orders: [],
                types: [],
                links: null,
                link: [],
                selected_filter_status: '',
                parentId: '',
                menuTitle: ''
            }
        },
        mounted() {
            this.show = true
            this.parentId = this.$route.params.id;
            this.$axios.get(this.$root.baseUrl + '/api/admin/children/' + this.parentId)
                .then(response => {
                    this.menuId = response.data.data.item.menu_id;
                    this.menuTitle = response.data.data.item.title;
                    this.menus = response.data.data.items;
                    this.show = false
                })
        },
        methods: {
            editItem(index) {
                let item = window.clone(this.menus[index]);
                item.index = index;
                this.edit = item;
                this.edit.type = "before"
                this.links = this.edit.links
                this.loadLinks(this.edit.type)
                this.$root.$emit('bv::show::modal', 'edit-modal')
            },

            loadLinks(name) {
                this.$axios.get(this.$root.baseUrl + '/api/admin/list/links?type=' + name)
                    .then(response => {
                        this.links = response.data.data
                    })
            },
            loadTypes() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/list/types')
                    .then(response => {
                        this.types = response.data.data
                    })
            },
            deleteItem(index) {
                window.swal({
                        title: "آیتم حذف شود؟",
                        text: "این عملیات غیر قابل بازگشت خواهد بود",
                        icon: "warning",

                        showCancelButton: true,
                        showConfirmButton: true,
                        dangerMode: true,
                        buttons: ["خیر", "بله حذف کن"]
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            let item = window.clone(this.menus[index]);
                            this.$axios.delete(this.$root.baseUrl + this.url + `/${item.id}`)
                                .then((response) => {
                                    this.$root.$delete(this.menus, index);
                                    window.swal({
                                        icon: "success",
                                        showConfirmButton: true,
                                        text: response.data.message,
                                        button: "باشه",
                                    })
                                })
                                .catch(error => {
                                    window.swal({
                                        title: 'خطا',
                                        showConfirmButton: true,
                                        text: error.response.data.message,
                                        button: "باشه",


                                    })
                                })
                        }
                    })
                    .catch(() => {

                    })

            },
            log() {
                this.orders = []
                this.menus.forEach(item => {
                    this.orders.push(item.id);
                });
                this.$axios.get(this.$root.baseUrl + '/api/admin/order/menu-items?orders=' + this.orders)
                    .then(response => {
                        window.swal({
                            title: 'با موفقیت تغییر کرد'
                        })
                    })
            },
            newFile() {
                this.disabled = true
                let form = document.getElementById('new-file')
                let formData = new FormData(form)

                this.$axios.post(this.$root.baseUrl + this.url, formData)
                    .then(response => {
                        this.$root.success_notification(response.data.message)
                        this.menus.unshift(response.data.data)
                        this.$root.$emit('bv::hide::modal', 'new-modal')
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            editFile() {
                this.disabled = true
                let form = document.getElementById('edit-file')
                let formData = new FormData(form)
                this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
                    .then(response => {
                        this.$root.$set(this.menus, this.edit.index, response.data.data);
                        this.$root.success_notification(response.data.message)
                        this.$root.$emit('bv::hide::modal', 'edit-modal');
                        this.edit = {}
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
        },
        created() {
            this.loadTypes()
            // window.newFile = response => {
            //     if(response.success) {
            //         this.menus.unshift(response.data)
            //         this.$root.$emit('bv::hide::modal', 'new-modal')
            //     }
            // }

            // window.editFile = response => {
            //     if(response.success) {
            //         this.$root.$set(this.menus, this.edit.index, response.data);
            //         this.$root.$emit('bv::hide::modal', 'edit-modal');
            //         this.edit = {}
            //     }
            // }
        },

    }
</script>